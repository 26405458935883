<script>
import SliderContent from '@/components/general/SliderContent'
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'GenericSlider',
  components: { SliderContent },
  props: {
    title: {
      type: String
    },
    items: {
      type: Array,
      required
    },
    type: {
      type: String,
      required
    },
    isLoading: {
      type: Boolean
    },
    emptyText: {
      type: String
    }
  },
  methods: {
    emitFunction (data) {
      this.$emit('emitGenericSlider', data)
    }
  }
}
</script>
<template>
  <div class="generic-slider--container">
    <div class="generic-slider--header_wrapper">
      <h5>{{ $t(title) }}</h5>
    </div>
    <slider-content
      v-if="items.length"
      :isLoading=isLoading
      :selectedTab="0"
      :tabItems="[items]"
      :type="type"
      @emitSliderContent="emitFunction"
    />
  </div>
</template>
<style lang="scss">
.generic-slider--container {
  position: relative;

  .generic-slider--header_wrapper {
    text-align: left;
    position: absolute;
    top: 0;
    h5 {
      font-size: 16px;
      line-height: 52px;
    }
  }

  .slider-content--container {
    margin: 0 -8px;

    .slider-button--prev {
      right: 56px;
    }

    .slider-button--next {
      right: 8px;
    }
  }

  .empty-container {
    margin: 0 auto;
    max-width: 400px;
    .empty-text {
      color: $neutral-medium;
      margin-bottom: 8px;
    }
    .v-image {
      margin: 20px auto 16px;
    }
  }
  .v-slide-group__content {
    grid-gap: 0;
  }
}
</style>
