<script>
import learningCardMixin from '@/mixins/learningCard'
import LearningCardContent from '@/components/general/LearningCardContent'
import NewContentCard from '@/components/contents/NewContentCard'
import { required } from 'vuelidate/lib/validators'
import NewPositionCard from '@/components/positions/NewPositionCard'
import IconCard from '@/components/general/IconCard'
import LearningCardList from '@/components/general/LearningCardList'
export default {
  name: 'SliderContent',
  mixins: [learningCardMixin],
  components: {
    NewPositionCard,
    NewContentCard,
    LearningCardContent,
    IconCard,
    Slider: () => import('@/components/general/Slider'),
    LearningCardList
  },
  data () {
    return {
      menuOptions: {
        content: ['edit', 'delete'],
        list: ['edit', 'delete']
      },
      addActionOptions: {
        content: ['addToList'],
        list: []
      },
      showConfirmToggleConclusion: false,
      loading: true
    }
  },
  props: {
    selectedTab: {
      type: Number,
      default: 0
    },
    tabItems: {
      type: Array,
      required
    },
    type: {
      type: String,
      required
    },
    emptyTexts: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    emitFunction (data) {
      this.$emit('emitSliderContent', data)
    },
    hitList (id) {
      this.$store.dispatch('attemptSubmitHitList', id)
    }
  }
}
</script>

<template>
  <div class="slider-content--container">
    <template v-for="(tab, index) in tabItems">
      <div class="fit-content" :key="index" v-if="selectedTab === index">
        <div class="skeleton" v-if="false">
          <v-skeleton-loader type="card-avatar, article, actions"
          width="280px" height="400px" />
        </div>
        <div v-else-if="!tab.length && emptyTexts[index]">
          <v-col offset-md="1" md="10" class="empty-container">
            <v-img :src="emptyTexts[index].image.name" :width="emptyTexts[index].image.w"
              :height="emptyTexts[index].image.h" class="empty-img" />
            <p class="empty-text text-center body--1">
              {{ emptyTexts[index].title }}
            </p>
            <p v-if="emptyTexts[index].subtitle" class="empty-text text-center body--1">
              {{ emptyTexts[index].subtitle }}
            </p>
            <v-btn v-if="emptyTexts[index].showDiscovery" outlined class="bold mt-3" color="#1200D3"
              @click="$router.push({ name: selectedWorkspace.type + '.discovery.index', query: { page: 1 } })">
              {{ $t('content.status.tab:completed.empty.button') }}
            </v-btn>
          </v-col>
        </div>
        <div v-else>
          <v-col md="12" class="ml-0 px-0 mt-3">
            <slider :key="tab.length" :items="tab" v-if="selectedTab === index">
              <template slot-scope="solutionsItem">
                <template>
                  <icon-card v-if="type === 'icon-card'" :key="solutionsItem.item.id"
                    :id="solutionsItem.item.id" :icon="solutionsItem.item.icon" :title="solutionsItem.item.title"
                    @emitIconCard="emitFunction"></icon-card>
                  <new-position-card v-else-if="type === 'positions'" :position="solutionsItem.item" />
                  <new-content-card v-else-if="type === 'courses'" :course="solutionsItem.item" />
                  <learning-card-list v-else-if="type === 'lists'" :list="solutionsItem.item" showItemsProgress originWindow=".discovery"
                  @interaction="hitList(solutionsItem.item.id)"></learning-card-list>
                  <learning-card-content v-else :content="solutionsItem.item.data"
                    :key="solutionsItem.item.id + Math.random()"></learning-card-content>
                </template>
              </template>
            </slider>
          </v-col>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="scss">
.skeleton {
  position: relative;
  top: 50px;
  display: flex;
}
</style>
